import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import functions from '../../assets/functions/common_functions';
import session from '../../assets/functions/session';
import LottieLoader from '../LottieLoader';
import url from '../../assets/url';
import cookie from 'react-cookies';
import axios from "axios";

export default class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.scrollToTopRef = React.createRef();
    this.state = {
      email: cookie.load("username") ? cookie.load("username") : "",
      login_error: localStorage.getItem("login_error") && localStorage.getItem("login_error") == "true" ? true : false,
      show_error_message: "",
      loader: false,
      dynamic_content: "",
      migrate_note: false,
      isUIReady: false,

    }
  }
  scrollToTop = () => {
    if (this.scrollToTopRef.current) {
        this.scrollToTopRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
}
  
componentDidUpdate(prevProps, prevState) {
  if ((this.state.isUIReady && !prevState.isUIReady) || (this.state.dynamic_content !== "" && prevState.dynamic_content === "")) {
      // Perform scroll to top action
      this.scrollToTop();
  }
}

  componentDidMount = () => {
    let action = "User landed on login page"
    let image = 'login_page_landing.png'
    functions.logUserActivity(action, image);
    let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
    if (dynamic_content != "") {
      this.setState({ dynamic_content: dynamic_content })
    }
    let session_id = cookie.load("session_id") ? cookie.load("session_id") : "null"
    if (session_id != "null") {
      session.updatePageSession(session_id, "login")
    }
    if (this.state.login_error == true) {
      let show_error_message = localStorage.getItem("show_error_message")
      this.setState({ show_error_message: show_error_message == "The specified user is not valid." ? show_error_message : "Login Link Expires. Please Sent Again" })
      localStorage.removeItem("show_error_message")
    }
    this.setState({ isUIReady: true });
  }

  handleForgotEmail = () => {
    let action = "User clicked on forget email button on login page"
    let image = 'forget_email_button_login_page.png'
    functions.logUserActivity(action, image);
    functions.pushToRoute(this, 'phone', 'login')
  }

  handleContinue = () => {
    let user_action = "User got error on login/index file handleContinue function";

    let action = "User clicked on continue button on login page"
    let image = 'continue_button_login_page.png'
    functions.logUserActivity(action, image);
    let self = this;
    let email = this.state.email ? this.state.email : ""
    if (email === '') {
      let error_body = url?.dynamic_content?.error_message?.enter_email?.en
      functions.logUserError(user_action, error_body);
      this.setState({ show_error_message: url?.dynamic_content?.error_message?.enter_email?.en })
    }
    else if (
      !email.match(/^([a-zA-Z0-9_.-]+@([\w-]+\.)+[\w-]{2,10})?$/)
    ) {
      let error_body = url.dynamic_content.error_message.invalid_email.en
      functions.logUserError(user_action, error_body);
      this.setState({ show_error_message: url.dynamic_content.error_message.invalid_email.en })
    }
    else {

      localStorage.setItem("email", email);
      this.setState({ loader: true })
      functions.CheckPasswordExistence(this, email, function (json_response) {
        let response = JSON.parse(json_response)
        if (response.data && response.data.success == 1 && response.data.user_exist_in_old_system == 1) {
          self.migrateUser(email)
        }
        else if (response.data && response.data.success == 1) {
          let dynamo_data = response.data.data.Items && response.data.data.Items.length > 0 ? response.data.data.Items[0] : []
          if (dynamo_data && dynamo_data.password) {
            if (dynamo_data.password == false) {
              self.handleSendLoginLink()
            }
            else {
              functions.pushToRoute(self, 'enterpassword', 'login')
            }
          }
          else {
            self.handleSendLoginLink()
          }
        } else if (response.data && response.data.success == 0) {
          let error_body = response.data.message
          functions.logUserError(user_action, error_body);
          self.setState({ show_error_message: response.data.message, loader: false });
        } else if (response.data && response.data.error == 1) {
          switch (response.data.data?.code) {
            case "UserNotFoundException":
              let error_body = url.dynamic_content.error_message.user_not_found.en
              functions.logUserError(user_action, error_body);
              self.setState({ show_error_message: url.dynamic_content.error_message.user_not_found.en, loader: false });
              break;
            case "NotAuthorizedException":
              let error_body1 = url.dynamic_content.error_message.account_disabled.en
              functions.logUserError(user_action, error_body1);
              self.setState({ show_error_message: url.dynamic_content.error_message.account_disabled.en, loader: false });
              break;
            default:
              self.setState({ loader: false });
              break;
          }
        }else{
          self.setState({ show_error_message: "User does not exist", loader: false });
        }
      })
    }
  }


  migrateUser = (email) => {
    let self = this
    let migration_uri = 'https://request.family.one/request/cron_makeSync.php';
    let dataset = {
      "email": email,
      sync: 0
    }
    self.setState({
      migrate_note: true
    })
    axios
      .request({
        url: migration_uri,
        data: JSON.stringify(dataset),
        method: "post"
      })
      .then(function (response) {
        console.log("getUserData response", response);
        localStorage.setItem("migrated", "true")
        self.handleSendLoginLink()
      });
  }

  handleSendLoginLink = () => {
    let user_action = "User got error on login/index file handleSendLoginLink function";
    let self = this;
    let session_id = cookie.load("session_id") ? cookie.load("session_id") : ""
    let email = this.state.email ? this.state.email : ""
    let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
    let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
    let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : session_id;
    let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
    let relative_id = cookie.load("relative_id") ? cookie.load("relative_id") : "";
    let http_referer = "null"
    let alternative_popup = cookie.load("showAlternativeEmailPopup") ? cookie.load("showAlternativeEmailPopup") : false;
    if (typeof cookie.load("http_referer") !== undefined &&
      cookie.load("http_referer") !== "undefined" &&
      cookie.load("http_referer") !== "null" &&
      cookie.load("http_referer") !== null &&
      cookie.load("http_referer")) { http_referer = cookie.load("http_referer") }
    let accept_auto_id = cookie.load("accept_auto_id") ? cookie.load("accept_auto_id") : "";
    functions.sendLoginLink(this, this.state.email, session_entered_id, relative_id, perks_or_main, http_referer, alternative_popup, accept_auto_id, function (response_json) {
      let response = JSON.parse(response_json);
      if (response.catch_error) {
        self.setState({ loader: false })
        let error_body = response.message
        functions.logUserError(user_action, error_body);
        self.setState({ show_error_message: response.message })
      }
      else if (!response.catch_error) {
        self.setState({ loader: false })
        if (response.data.error === 1) {
          let error_body = response.data.message
          functions.logUserError(user_action, error_body);
          self.setState({ show_error_message: response.data.message })
        } else if (response.data.statusCode === 400) {
          let error_body = response.data.message
          functions.logUserError(user_action, error_body);
          self.setState({ show_error_message: response.data.message })
        }
        if (response.data.success === 1) {
          cookie.remove("alternative_popup", { path: "/" })
          let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
          let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
          let migrated = localStorage.getItem("migrated") && localStorage.getItem("migrated") == "true" ? "true" : "false";
          let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
          Object.keys(localStorage).forEach(key => !['phone_transfer', 'email_transfer'].includes(key) && localStorage.removeItem(key));


          localStorage.setItem("user_signup_process", "false")
          localStorage.setItem("session_entered_id", session_entered_id)
          localStorage.setItem("previous_state", previous_state)
          localStorage.setItem("current_state", current_state)
          localStorage.setItem("program", JSON.stringify(program))
          localStorage.setItem("perks_or_main", perks_or_main)
          localStorage.setItem("program_list", JSON.stringify(program_list))
          localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))

          if (migrated == "true") {
            localStorage.setItem("migrated", "true")
          }

          localStorage.setItem("email", email)

          functions.pushToRoute(self, 'sent', 'login')
        }
      }
    })
  }

  render() {
    const { dynamic_content } = this.state
    return (
      <div ref={this.scrollToTopRef} className="container cstmSignUp">
        {/* {dynamic_content != "" && */}
        <div className="row">
          <div className="common_mainAddOn">
          <div className='usrLoginSetBg'>
          <div className="common_main common_mainIner">
           <div>
            <div className="sign_backlink sign_backlinkNw"
             onClick={() => {
              let action = "User clicked on back button on login page"
              let image = 'back_button_login_page.png'
              functions.logUserActivity(action, image);
              this.props.history.push("/home")
            }}>
              <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
            </div>

            <div className="log_up">
              <h1>{dynamic_content && dynamic_content.static_text.login.log_in.en ? dynamic_content.static_text.login.log_in.en : "Log in"}</h1>
            </div>

            <div className='loginYourAc'><h3>Log in to your account</h3></div>

            {this.state.loader &&
              <div className="loader_div">
                <center>
                  <LottieLoader className="loader_class" color="secondary" />
                </center>
                {this.state.migrate_note &&
                  <div style={{ marginTop: "20px" }}>
                    <p className="migrate_note">Welcome to the new family.one</p>
                    <p className="migrate_note">It may take up to 10 seconds to migrate your profile.</p>
                    <p className="migrate_note">We'll email you a login link when migration is complete.</p>
                  </div>
                }
              </div>
            }
            {!this.state.loader &&
              <div className="logup_form loginFormCstm">
                {/* <form autoComplete="off"> */}
                <div className="relative_sec loginFormField">
                  <input
                    autoComplete='off'
                    //hiddenLabel="Enter your email"
                    placeholder="Email Address"
                    type="email"
                    value={this.state.email}
                    onChange={(e) => { this.setState({ email: e.target.value.toLowerCase(), show_error_message: '' }) }}
                    onKeyPress={event => {
                      if (event.key === "Enter") {
                        this.handleContinue()
                      }
                    }}
                    margin="normal"
                    className="logup_first"
                  />

                  {/* {this.state.email && this.state.email.length > 0 &&
                    <img
                      onClick={() => { this.setState({ email: "", show_error_message: "" }) }}
                      src={url.family_one_image_url + 'Group168.png'}
                      className="clearimg"
                      alt=""
                      title=""
                    />
                  } */}

                </div>
                {this.state.show_error_message && this.state.show_error_message != "" &&
                  <p className="error_message">
                    {this.state.show_error_message}
                  </p>
                }
                <div className="logup_submit">
                  <Button size="medium" color="primary" onClick={() => { this.handleContinue() }}>
                    {dynamic_content && dynamic_content.static_text.login.log_in.en ? dynamic_content.static_text.login.log_in.en : "Log in"}
                  </Button>
                </div>

                {/* </form> */}
              </div>
            }
            {!this.state.loader &&
              <div className="forget_email">
                <p onClick={() => { this.handleForgotEmail() }}>{dynamic_content && dynamic_content.static_text.login.forgot_email.en ? dynamic_content.static_text.login.forgot_email.en : "Forgot Email?"}</p>
              </div>
            }
           </div>  

           {/* Add Animation Here */}
           {/* <div className="signupConectCircleInr">
              <div className="signupConectInr"></div>  
            </div>   */}
            {/* End of Animation Here */}
            
          </div>
          </div>
          </div>
        </div>
        {/* } */}
      </div>
    );
  }
}