import React from 'react';
import '../../assets/css/style.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MaskedInput from 'react-text-mask';
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
import cookie from 'react-cookies';
import LottieLoader from '../LottieLoader';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
const C = CSSPlugin;


export default class Entercontest extends React.Component {
    constructor(props) {
        super(props)
        localStorage.setItem("resend_counter", 32)
        localStorage.setItem("call_counter", 32)
        this.state = {
            show_error_message: "",
            open_dailog: false,
            send_code: false,
            loader: false,
            code: "+1",
            socket: null,
            messages: [],
            phone_number: localStorage.getItem("phone_number") ? localStorage.getItem("phone_number") : "",
            phone_number_verified: localStorage.getItem("phone_number_verified") && (localStorage.getItem("phone_number_verified") == "true" || localStorage.getItem("phone_number_verified") == true) ? true : false,
            verify_code: "",
            dynamic_content: "",
            call_counter: localStorage.getItem("call_counter") ? parseInt(localStorage.getItem("call_counter")) : 32,
            resend_counter: localStorage.getItem("resend_counter") ? parseInt(localStorage.getItem("resend_counter")) : 32
        }
    }

    toggle = () => {
        this.setState({
          open_dailog: !this.state.open_dailog
        });
    }

    componentDidMount = () => {
        this.setState({ show_error_message: "" })
        // let TL22 = new TimelineMax();
        // TL22.fromTo('.fade_it_up', 0.4, { opacity: 0, y: -10 }, { opacity: 1, y: 0 })
    }

    componentDidUpdate(prevProps, prevState) {
        // Check if phone_number has changed
        if (prevState.send_code !== this.state.send_code) {
          if (this.state.socket) {
            this.state.socket.close(); // Close the previous WebSocket connection before opening a new one
          }
          this.openWebSocket(); // Open a new WebSocket connection with the updated phone number
        }
      }

    componentDidMount = () => {
        // Step 1: Retrieve the value from localStorage
        const phoneTransfer = localStorage.getItem('phone_transfer');
        const emailTransfer = localStorage.getItem('email_transfer');
        const email = localStorage.getItem('email');
    
        // Step 2: Check if the value exists
        if (phoneTransfer && emailTransfer && emailTransfer === email) {
            this.setState({ phone_number: phoneTransfer })
        }
    }


    openWebSocket = () => {
        const { phone_number } = this.state; // Get phone number from state
        const socketUrl = 'wss://8ypsjdkjnd.execute-api.us-east-1.amazonaws.com/production';
        const socket = new WebSocket(`${socketUrl}?phone_number=${encodeURIComponent(phone_number)}`);
    
        socket.onopen = () => {
          console.log('WebSocket connection established');
        };
    
        socket.onmessage = (event) => {
          const incomingMessage = JSON.parse(event.data);
          this.setState((prevState) => ({
            messages: [...prevState.messages, incomingMessage]
          }));
          console.log('Received message:', incomingMessage);
    
          // Show specific alerts based on incoming messages
          if (incomingMessage.message === 'Connected') {
            alert('WebSocket connected successfully!');
          }
          if (incomingMessage.message === 'Your phone number has been verified.') {
            console.log("nhgf")
            alert(incomingMessage.message);
            this.handleNext("ComesFromSocket")
          }
        };
     // // Handle WebSocket closure and immediate reconnection
            // socket.onclose = (event) => {
            //     console.log('WebSocket closed. Reconnecting immediately...');
            //     openWebSocket();  // Reconnect immediately without waiting
            // };
    
            // // Handle WebSocket errors
            // socket.onerror = (error) => {
            //     console.log('WebSocket Error: ', error);
            //     socket.close();  // Close the socket and trigger reconnection
            // };
    
            // Store the socket in the component's state
        // Store the WebSocket instance in state
        this.setState({ socket });
      };
    
    
    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        this.setState({ show_error_message: "" })
        this.interval = setInterval(() => { this.handleCounter() }, 1000);

            // const { socket } = this.state;
            // if (socket) {
            //     socket.close();
            // }
        
    }

    handleCounter = () => {
        if (this.state.resend_counter > 0) {
            this.setState({ resend_counter: this.state.resend_counter - 1 })
            localStorage.setItem("resend_counter", this.state.resend_counter)
        }
        if (this.state.resend_counter < 0) {
            clearInterval(this.interval);
        }
        if (this.state.call_counter > 0) {
            this.setState({ call_counter: this.state.call_counter - 1 })
            localStorage.setItem("call_counter", this.state.call_counter)
        }
        if (this.state.call_counter < 0) {
            clearInterval(this.interval);
        }

    }

    handleContinue = () => {
        let success_action = "User clicked on continue button in enterphone page"
        let image = 'continue_button_enterphone_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        let user_action = "User got error on innerpages/enterphone file handleContinue function";

        this.setState({ show_error_message: "" })
        localStorage.setItem("phone_number", this.state.phone_number);
        let phone_number = this.formatPhoneNumber(this.state.phone_number);
        if (phone_number == null || phone_number === "" || phone_number.length < 13) {
            let body = url.dynamic_content.error_message.invalid_phone.en
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.invalid_phone.en })
            this.removeAnimationEffect()
            // let TL = new TimelineMax();
            // TL.to('.program_entry_loader', 0.5, { opacity: 0 })
            //     .fromTo('.phone_verification_block', 0.5, { opacity: 0 }, { opacity: 1, height: 'auto' }, 0)
        } else {
            this.handleAnimation();
            let area_code = phone_number.substring(1, 4).toString();
            let mode = "msg";
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
            sub_id = sub_id ? sub_id : ""
            let country_code = this.state.code;
            let program_id = this.props.program_id;
            let partner_id = this.props.partner_id;
            let action = "send_code";
            self.setState({ loader: true })
            functions.sendVerifyCode(this, action, phone_number, sub_id, country_code, area_code, mode, program_id, function (response_json) {
                let response = JSON.parse(response_json);

                if (response.error == 1 || (response.data && response.data.result && response.data.result == "failed")) {
                    let TL = new TimelineMax();
                    TL.to('.program_entry_loader', 0.1, { opacity: 0 })
                        .fromTo('.phone_verification_block', 0.5, { opacity: 0 }, { opacity: 1, height: 'auto' }, 0)
                }

                if (response.error == 1) {
                    self.setState({ loader: false })
                    let body = url.dynamic_content.error_message.wrong_happend.en
                    functions.logUserError(user_action, body);
                    self.setState({ show_error_message: url.dynamic_content.error_message.wrong_happend.en })
                    localStorage.setItem("send_code", "false")
                }
                else if (response.data && response.data.result && response.data.result == "failed") {
                    if (response.data.mode == "existing") {
                        localStorage.setItem("phone_number_required", "false")
                        localStorage.setItem("show_phone", "false");
                        self.props.onSubmit()
                    }
                    else {
                        self.setState({ loader: false })
                        let body = response.data.message
                        functions.logUserError(user_action, body);
                        self.setState({ show_error_message: response.data.message })
                        localStorage.setItem("send_code", "false")
                    }

                }
                else if (response.data == "Successfull") {
                    let success_action = "User get success response in sendVerifyCode function on enterphone page"
                    functions.logUserActivity(success_action, null);
                    self.setState({ loader: false })
                    self.setState({ send_code: true, resend_counter: 32 })
                    localStorage.setItem("send_code", "true")
                    localStorage.setItem("resend_counter", "32")
                    let element = document.getElementById("phone_enter")
                    console.log("element", element)
                    
                    if (partner_id == "1" || partner_id == '15') {
                        self.props.contestEntryKff('');
                    }
                    element.click();
                }
                else if (response.data == "Exisitng Record") {
                    let success_action = "User' phone number is a existing record enterphone page"
                    let image = 'phone_number_already_exist_enterphone_page.png'
                    functions.logUserActivity(success_action, image);
                    self.setState({ loader: false })
                    localStorage.setItem("phone_number_required", "false")
                    localStorage.setItem("show_phone", "false");
                    self.props.onSubmit();
                }
            });
        }
    }

    handleAnimation = () => {
        let TL = new TimelineMax();
        TL.fromTo('.phone_verification_block', 0.5, { opacity: 1 }, { opacity: 0, height: '200px' })
            .to('.program_entry_loader', 0.5, { opacity: 1 })
    }

    removeAnimationEffect = () => {
        let TL = new TimelineMax();
        TL.fromTo('.phone_verification_block', 0.5, { opacity: 0 }, { opacity: 1, height: 'auto' })
            .to('.program_entry_loader', 0.5, { opacity: 0 })
    }

    formatPhoneNumber(phoneNumberString) {
        this.setState({ show_error_message: "" })
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ')' + match[2] + '-' + match[3]
        }
        return null
    }

    handleEnter = () => {
        let self = this
        this.handleAnimation();
        setTimeout(() => {
            let success_action = "User clicked on Enter button enterphone page"
            functions.logUserActivity(success_action, null);
            localStorage.setItem("phone_number_required", "false")
            localStorage.setItem("show_phone", "false");
            self.setState({ show_error_message: "" })
            self.props.onSubmit()
        }, 500);
    }

    handleResend = () => {
        let success_action = "User clicked on resend button in enterphone page"
        let image = 'resend_button_enterphone_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        localStorage.setItem("phone_number", this.state.phone_number);
        let phone_number = this.formatPhoneNumber(this.state.phone_number);
        self.setState({ resend_counter: 32 })
        self.setState({ show_error_message: "" })
        localStorage.setItem("resend_counter", 32)
        if (phone_number == null || phone_number === "" || phone_number.length < 13) {
            let user_action = "User got error on innerpages/enterphone file handleResend function";
            let body = url.dynamic_content.error_message.invalid_phone.en
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.invalid_phone.en })
        } else {
            let area_code = phone_number.substring(1, 4).toString();
            let mode = "msg";
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
            sub_id = sub_id ? sub_id : ""
            let country_code = this.state.code;
            let action = "send_code";
            
            let program_id = this.props.program_id;
            self.setState({ loader: true })
            functions.sendVerifyCode(this, action, phone_number, sub_id, country_code, area_code, mode, program_id, function (response_json) {
                let response = JSON.parse(response_json);
                if (response.error == 1) {
                    self.setState({ loader: false })
                    localStorage.setItem("send_code", "false")
                }
                else if (response.data == "Successfull") {
                    let success_action = "User get success response in sendVerifyCode function on enterphone page"
                    functions.logUserActivity(success_action, null);
                    self.setState({ loader: false })
                    self.setState({ send_code: true, resend_counter: 32 })
                    localStorage.setItem("send_code", "true")
                    localStorage.setItem("resend_counter", "32")
                    self.setState({ call_counter: 32 })
                    localStorage.setItem("call_counter", "32")
                }
                else if (response.data == "Exisitng Record") {
                    let success_action = "User' phone number is a existing record enterphone page"
                    let image = 'phone_number_already_exist_enterphone_page.png'
                    functions.logUserActivity(success_action, image);
                    self.setState({ loader: false })
                    localStorage.setItem("phone_number_required", "false")
                    localStorage.setItem("show_phone", "false");
                    self.props.onSubmit();
                }
            });
        }
    }

    handleNext = (action) => {
        let success_action = "User clicked on nect button enterphone page"
        let image = 'next_button_enterphone_page.png'
        functions.logUserActivity(success_action, image);
        this.handleAnimation();
        let self = this;
        if(action === "ComesFromSocket"){
            let phone_number = this.formatPhoneNumber(this.state.phone_number);
            let area_code = phone_number.substring(1, 4).toString();
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
            sub_id = sub_id ? sub_id : ""
            let country_code = this.state.code;
            let verify_code = null
            if(!phone_number) {
                this.setState({ show_error_message: url.dynamic_content.error_message.invalid_phone.en });
                return;
            }
            this.setState({ show_error_message: "" });

                    let success_action = "User's phone number is verified successfully enterphone page"
                    const phone_transfer = localStorage.getItem('phone_transfer'), 
                        email_transfer = localStorage.getItem('email_transfer');
                    if(phone_transfer && email_transfer) {
                        localStorage.removeItem('email_transfer');
                        localStorage.removeItem('phone_transfer');
                        this.setState({open_dailog : true})
                        setTimeout(()=>{
                            this.setState({open_dailog : false})
                        }, 3000)
                    }
                    let image = 'phone_number_verified_enterphone_page.png'
                    functions.logUserActivity(success_action, image);
                    // self.removeAnimationEffect();
                    localStorage.setItem("phone_number_required", "false")
                    localStorage.setItem("show_phone", "false");
                    localStorage.setItem("phone_verified", "true");
                    self.setState({ show_error_message: "" })
                    let element = document.getElementById("verify_code_enter")
                    console.log("element", element)
                    element.click();
                    self.props.onSubmit(phone_number)
                
       
        }
        else{
        if (this.state.verify_code === "" || !this.state.verify_code) {
            let user_action = "User got error on innerpages/enterphone file handleNext function";
            let body = url.dynamic_content.error_message.verify_code_missing.en
            functions.logUserError(user_action, body);
            this.setState({
                show_error_message: url.dynamic_content.error_message.verify_code_missing.en
            });
            this.removeAnimationEffect();
        } else if (!this.state.verify_code.match(/^\d{6}$/)) {
            let user_action = "User got error on innerpages/enterphone file handleNext function";
            let body = url.dynamic_content.error_message.invalid_verify_code.en
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.invalid_verify_code.en });
            this.removeAnimationEffect();
        } else {
            let phone_number = this.formatPhoneNumber(this.state.phone_number);
            let area_code = phone_number.substring(1, 4).toString();
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
            sub_id = sub_id ? sub_id : ""
            let country_code = this.state.code;
            let verify_code = this.state.verify_code
            let action = "verify_code";
            if(!phone_number) {
                this.setState({ show_error_message: url.dynamic_content.error_message.invalid_phone.en });
                return;
            }else if(!verify_code){
                this.setState({ show_error_message: url.dynamic_content.error_message.invalid_verify_code.en });
                return;
            }
            this.setState({ show_error_message: "" });
            functions.checkVerifyCode(this, action, phone_number, sub_id, country_code, area_code, verify_code, function (response_json) {
                let response = JSON.parse(response_json);
                console.log("308 checkVerifyCode res", response)
                if (response.error == 1 || (response.data&&(response.data.result == "Not Match" || response.data.result == "Match"))) {
                    self.removeAnimationEffect();
                }
                if (response.error == 1) {
                    self.setState({ show_error_message: "" })
                    localStorage.setItem("send_code", "false")
                }
                else if (response.data&&response.data.result == "Not Match") {
                    let user_action = "User got error on innerpages/enterphone file handleNext function";
                    let body = url.dynamic_content.error_message.incorrect_code.en
                    functions.logUserError(user_action, body);
                    self.setState({ show_error_message: url.dynamic_content.error_message.incorrect_code.en })
                }
                else if (response.data &&(response.data == 'Match' || response.data.result == "Match")) {
                    let success_action = "User's phone number is verified successfully enterphone page"
                    const phone_transfer = localStorage.getItem('phone_transfer'), 
                        email_transfer = localStorage.getItem('email_transfer');
                    if(phone_transfer && email_transfer) {
                        localStorage.removeItem('email_transfer');
                        localStorage.removeItem('phone_transfer');
                        this.setState({open_dailog : true})
                        setTimeout(()=>{
                            this.setState({open_dailog : false})
                        }, 3000)
                    }
                    let image = 'phone_number_verified_enterphone_page.png'
                    functions.logUserActivity(success_action, image);
                    // self.removeAnimationEffect();
                    localStorage.setItem("phone_number_required", "false")
                    localStorage.setItem("show_phone", "false");
                    localStorage.setItem("phone_verified", "true");
                    self.setState({ show_error_message: "" })
                    let element = document.getElementById("verify_code_enter")
                    console.log("element", element)
                    element.click();
                    self.props.onSubmit(phone_number)
                }
            });
        }
    }
    }

    handleClick = () => {

        const phoneNumber = "+1234567890";  // Your phone number for verification
        const defaultMessage = "YES";       // The default message to send
        // Check if the device is mobile
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        if (isMobile) {
            // Mobile device: Open the default SMS app
            const smsHref = `sms:${phoneNumber}?&body=${encodeURIComponent(defaultMessage)}`;
            window.location.href = smsHref;
        } else {
            // Desktop device: Show tooltip or alert
            alert(`Please send a message with "${defaultMessage}" to ${phoneNumber} to get verified.`);
        }
    };

    handleCall = () => {
        let success_action = "User clicked on call button enterphone page"
        let image = 'call_button_enterphone_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        self.setState({ call_counter: 32 })
        self.setState({ show_error_message: "" })
        localStorage.setItem("call_counter", 32)
        localStorage.setItem("phone_number", this.state.phone_number);
        let phone_number = this.formatPhoneNumber(this.state.phone_number);
        if (phone_number == null || phone_number === "" || phone_number.length < 13) {
            let user_action = "User got error on innerpages/enterphone file handleCall function";
            let body = url.dynamic_content.error_message.invalid_phone.en
            functions.logUserError(user_action, body);
            this.setState({ show_error_message: url.dynamic_content.error_message.invalid_phone.en })
        } else {
            this.setState({ show_error_message: "" })
            let area_code = phone_number.substring(1, 4).toString();
            let mode = "call";
            let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
            sub_id = sub_id ? sub_id : ""
            let country_code = this.state.code;
            let action = "send_code";
            self.setState({ loader: true })
            functions.sendVerifyCode(this, action, phone_number, sub_id, country_code, area_code, mode, '', function (response_json) {
                let response = JSON.parse(response_json);
                if (response.error == 1) {
                    self.setState({ loader: false })
                    localStorage.setItem("send_code", "false")
                }
                else if (response.data == "Successfull") {
                    let success_action = "User got success response on sendVerifyCode enterphone page"
                    let image = 'success_sendverifycode_enterphone_page.png'
                    functions.logUserActivity(success_action, image);
                    self.setState({ loader: false })
                    self.setState({ send_code: true, resend_counter: 32 })
                    localStorage.setItem("send_code", "true")
                    localStorage.setItem("resend_counter", "32")
                    self.setState({ call_counter: 32 })
                    localStorage.setItem("call_counter", "32")
                }
                else if (response.data == "Exisitng Record") {
                    let success_action = "User' phone number is a existing record enterphone page"
                    let image = 'phone_number_already_exist_enterphone_page.png'
                    functions.logUserActivity(success_action, image);
                    self.setState({ loader: false })
                    localStorage.setItem("phone_number_required", "false")
                    localStorage.setItem("show_phone", "false");
                    self.props.onSubmit();
                }
            });
        }
    }

    render() {
        const { dynamic_content } = this.state
        let {program} = this.props.props
        if(!program) program = this.props.props.program_detail
        return (
            <div>
                {dynamic_content != "" && this.props.partner_id == "7" &&
                    <div >
                        {!this.state.send_code &&
                            <div className="row rel_block fade_it_up">
                                <div className="phone_verification_block" style={{ position: "relative" }}>
                                    <div className="phone_verify_backlink"><img onClick={() => { this.props.Back() }} src={url.family_one_image_url + 'Group147.png'} alt="" title="" /></div>
                                    <div className="contest_up">
                                        <h1>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.enter_contest.en : "Enter Contest"}</h1>

                                        {/* <h2>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.enter_mobile_number.en : "Enter a mobile number."}</h2> */}
                                        {this.state.phone_number_verified == false &&
                                            <h2 style={{ marginTop: "-3px" }}>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.enter_mobile_progenic_text.en : "Enter a mobile number. A Progenic rep will contact you shortly"}</h2>
                                        }
                                        {this.state.phone_number_verified == true &&
                                            <h2 style={{ marginTop: "-3px" }}>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.continue_mobile_progenic_text.en : "Continue to have a Progenics rep contact you shortly"}</h2>
                                        }
                                    </div>
                                    {/* {!this.state.loader && */}
                                    {this.state.phone_number_verified == false &&
                                        <div>
                                            <div className="contest_process1">
                                                <FormControl className="phone_form_class">
                                                    <Select
                                                        className="phone_select_class"
                                                        value={this.state.code}
                                                        onChange={(e) => { this.setState({ code: e.target.value, show_error_message: "" }) }}
                                                    >
                                                        <MenuItem value={"+1"}>+1</MenuItem>
                                                        <MenuItem value={"+91"}>+91</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className=""
                                                    type="tel"
                                                    // placeholder="55-5342-8400"
                                                    value={this.state.phone_number}
                                                    onChange={(e) => { this.setState({ phone_number: e.target.value, show_error_message: '' }) }}
                                                    onKeyPress={event => {
                                                        if (event.key === "Enter") {
                                                            this.handleContinue()
                                                        }
                                                    }}
                                                    autoFocus={true}
                                                />
                                                <div id="phone_enter"></div>
                                                <img onClick={() => { this.setState({ phone_number: "" }) }} src={url.family_one_image_url + 'Group168.png'} alt="" title="" className="clearcontest_img" />
                                                {this.state.show_error_message && this.state.show_error_message != "" &&
                                                    <p className="error_message">
                                                        {this.state.show_error_message}
                                                    </p>
                                                }
                                                <p>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.you_will_receive.en : "You’ll receive a text message with a verification code"}</p>
                                            </div>
                                            <div className="contest_msg">
                                                <div className="contest_opt">
                                                    <Button size="medium" color="primary" onClick={() => { this.handleContinue() }} >
                                                        {dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.continue.en : "Conitnue"}
                                                    </Button>
                                                </div>
                                                <p>{dynamic_content.static_text.enterphone ? ReactHtmlParser(dynamic_content.static_text.enterphone.contact_message.en) : "We collect your first name, last name and email address as the most basic account identifiers and default means for you to access your account and us to contact you."}</p>
                                            </div>
                                        </div>
                                    }
                                    {this.state.phone_number_verified == true &&
                                        <div>
                                            <div className="contest_process1">
                                                <label className="phone_number_label"> +1 {this.state.phone_number.replace('(', '-').replace(')', '-')}</label>
                                                <div id="phone_enter"></div>
                                            </div>
                                            <div className="contest_msg">
                                                <div className="contest_opt">
                                                    <Button size="medium" color="primary" onClick={() => { this.handleEnter() }} >
                                                        {dynamic_content.static_text.enterphone.enter ? dynamic_content.static_text.enterphone.enter.en : "Enter"}
                                                    </Button>
                                                </div>
                                                {/* <p>{dynamic_content.static_text.enterphone ? ReactHtmlParser(dynamic_content.static_text.enterphone.contact_message.en) : "We collect your first name, last name and email address as the most basic account identifiers and default means for you to access your account and us to contact you."}</p> */}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="program_entry_loader">
                                    <LottieLoader />
                                </div>
                            </div>

                        }
                        {this.state.send_code &&
                            <div className="row rel_block fade_it_up">

                                <div className="phone_verification_block" style={{ position: "relative" }}>
                                    <div className="phone_verify_backlink"><img onClick={() => { this.setState({ send_code: false }); localStorage.setItem("send_code", "false") }} src={url.family_one_image_url + 'Group147.png'} alt="" title="" /></div>
                                    <div className="contest_up">
                                        <h1>{this.state.phone_number}</h1>
                                        <h2>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.verification_code.en : "Your verification code:"}</h2>
                                    </div>
                                    <div className="contest_process2">
                                        <TextField
                                            autoFocus={true}
                                            autoComplete='off'
                                            label="Verification code"
                                            // type="number"
                                            margin="normal"
                                            inputProps={{ maxLength: 6 }}
                                            type="text"
                                            value={this.state.verify_code}
                                            onChange={(event) => {
                                                if (event.target.value.match(/^[0-9]{0,6}$/)) {
                                                    this.setState({ verify_code: event.target.value, show_error_message: '' })
                                                }
                                            }}
                                            onKeyPress={event => {
                                                if (event.key === "Enter") {
                                                    this.handleNext()
                                                }
                                            }}
                                        />
                                    </div>
                                    <div id="verify_code_enter"></div>
                                    {this.state.show_error_message && this.state.show_error_message != "" &&
                                        <p className="error_message" style={{ marginLeft: "0px" }}>
                                            {this.state.show_error_message}
                                        </p>
                                    }
                                    <div className="contest_call">
                                        {this.state.resend_counter < 1 &&
                                            <div className="resend_active" onClick={() => { this.handleResend() }}><p>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.resend.en : "Resend"}</p></div>
                                        }
                                        {this.state.resend_counter > 0 &&
                                            <div className="resend" > <p><span>Resend (00:{this.state.resend_counter > 9 ? this.state.resend_counter : "0" + this.state.resend_counter})</span></p></div>
                                        }
                                        {this.state.call_counter < 1 &&
                                            <div onClick={() => { this.handleCall() }}><p style={{ textAlign: "right" }}>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.call.en : "Call"}</p></div>
                                        }
                                        {this.state.call_counter > 0 &&
                                            <div><p style={{ textAlign: "right" }}>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.call.en : "Call"}</p></div>
                                        }

                                    </div>

                                    <div className="contest_msg">
                                        <div className="contest_opt">
                                            <Button size="medium" color="primary" onClick={() => { this.handleNext() }}>
                                                { program && program.partner_id=="14"?"Consent & Submit":dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.submit.en : "Done"}
                                            </Button>
                                        </div>
                                        <p>{dynamic_content.static_text.enterphone ? ReactHtmlParser(dynamic_content.static_text.enterphone.contact_message.en) : "We collect your first name, last name and email address as the most basic account identifiers and default means for you to access your account and us to contact you."}</p>
                                    </div>
                                </div>
                                <div className="program_entry_loader">
                                    <LottieLoader />
                                </div>
                            </div>
                        }

                    </div>
                }
                {dynamic_content != "" && this.props.partner_id != "7" &&
                    <div >
                        {!this.state.send_code &&
                            <div className="row rel_block fade_it_up">
                                <div className="phone_verification_block" style={{ position: "relative" }}>
                                    
                                   <div className='completeYrEntry'>
                                     <div className="phone_verify_backlink phone_verify_backlinkCstm">
                                        <img onClick={() => { this.props.Back() }} src={require('../../assets/images/backArow.svg')} alt="" title="" />
                                    </div>
                                    <label>Complete your entry</label>
                                    </div>
                                    <div className='enterMobCstm'><label>Enter a mobile number</label></div>
                                    {/* <div className="contest_up">
                                        <h1>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.enter_contest.en : "Enter Contest"}</h1>

                                        <h2>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.enter_mobile_number.en : "Enter a mobile number."}</h2>
                                        {this.props.partner_id && this.props.partner_id == "7" && this.state.phone_number_verified == false &&
                                            <h2 style={{ marginTop: "-3px" }}>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.enter_mobile_progenic_text.en : "A Progenic rep will contact you shortly"}</h2>
                                        }
                                        {this.props.partner_id && this.props.partner_id == "7" && this.state.phone_number_verified == true &&
                                            <h2 style={{ marginTop: "-3px" }}>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.enter_mobile_progenic_text.en : "A Progenic rep will contact you shortly"}</h2>
                                        }
                                    </div> */}

                                    {/* {!this.state.loader && */}
                                    <div className="contest_process1 contest_process1Cstm">
                                        <FormControl className="phone_form_class 777">
                                            <Select
                                                className="phone_select_class"
                                                value={this.state.code}
                                                onChange={(e) => { this.setState({ code: e.target.value, show_error_message: "" }) }}
                                            >
                                                <MenuItem value={"+1"}>+1</MenuItem>
                                                <MenuItem value={"+91"}>+91</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <MaskedInput
                                            mask={['(', /[1-9]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            className=""
                                            type="tel"
                                            // placeholder="55-5342-8400"
                                            value={this.state.phone_number}
                                            onChange={(e) => { this.setState({ phone_number: e.target.value, show_error_message: '' }) }}
                                            onKeyPress={event => {
                                                if (event.key === "Enter") {
                                                    this.handleContinue()
                                                }
                                            }}
                                            autoFocus={true}
                                        />
                                        <div id="phone_enter"></div>
                                        {/* <img onClick={() => { this.setState({ phone_number: "" }) }} src={url.family_one_image_url + 'Group168.png'} alt="" title="" className="clearcontest_img" /> */}
                                    </div>
                                    {this.state.show_error_message && this.state.show_error_message != "" &&
                                      <div className='phoneEntrMsg'>
                                       <p className="error_message">{this.state.show_error_message}</p>
                                      </div> 
                                    }

                                    <div className='entrPhoneTxt'>
                                        <p>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.you_will_receive.en : "You’ll receive a text message with a verification code"}</p>
                                    </div>
                                    {/* } */}
                                    <div className="contest_msg">
                                        {/* {!this.state.loader && */}
                                        <div className="contest_opt">
                                            <Button size="medium" color="primary" onClick={() => { this.handleContinue() }} >
                                                {dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.continue.en : "Conitnue"}
                                            </Button>
                                        </div>
                                        {/* } */}
                                        {this.props.partner_id!=="14"&&<p>{dynamic_content.static_text.enterphone ? ReactHtmlParser(dynamic_content.static_text.enterphone.contact_message.en) : "We collect your first name, last name and email address as the most basic account identifiers and default means for you to access your account and us to contact you."}</p>}
                                    </div>
                                </div>
                                <div className="program_entry_loader">
                                    <LottieLoader />
                                </div>
                            </div>

                        }
                        {this.state.send_code &&
                            <div className="row rel_block fade_it_up verifyCodeMain">

                                <div className="phone_verification_block verifyCodeUpr" style={{ position: "relative" }}>
                                    <div className="phone_verify_backlink">
                                       <img onClick={() => { this.setState({ send_code: false }); localStorage.setItem("send_code", "false") }} src={require('../../assets/images/backArow.svg')} alt="" title="" />
                                    </div>
                                    <div className="contest_up">
                                        <h1>{this.state.phone_number}</h1>
                                        <h2>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.verification_code.en : "Your verification code:"}</h2>
                                    </div>
                                    <div className="verificationCode">
                                        <div><label>Verification code</label></div>
                                        <TextField
                                            autoFocus={true}
                                            autoComplete='off'
                                            hiddenLabel="Verification code"
                                            // type="number"
                                            margin="normal"
                                            inputProps={{ maxLength: 6 }}
                                            type="text"
                                            value={this.state.verify_code}
                                            onChange={(event) => {
                                                if (event.target.value.match(/^[0-9]{0,6}$/)) {
                                                    this.setState({ verify_code: event.target.value, show_error_message: '' })
                                                }
                                            }}
                                            onKeyPress={event => {
                                                if (event.key === "Enter") {
                                                    this.handleNext()
                                                }
                                            }}
                                        />
                                    </div>
                                    <div id="verify_code_enter"></div>
                                    {this.state.show_error_message && this.state.show_error_message != "" &&
                                        <p className="error_message" style={{ marginLeft: "0px" }}>
                                            {this.state.show_error_message}
                                        </p>
                                    }
                                    <div className="contest_call verfiyCodeInfo">
                                        {this.state.resend_counter < 1 &&
                                            <div className="resend_active" onClick={() => { this.handleResend() }}><p>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.resend.en : "Resend"}</p></div>
                                        }
                                        {this.state.resend_counter > 0 &&
                                            <div className="resend" > <p><span>Resend (00:{this.state.resend_counter > 9 ? this.state.resend_counter : "0" + this.state.resend_counter})</span></p></div>
                                        }

                                        <div className="resend_active" onClick={() => { this.handleClick()}}><p>Send SMS</p></div>
                                        {this.state.call_counter < 1 &&
                                            <div onClick={() => { this.handleCall() }}><p style={{ textAlign: "right" }}>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.call.en : "Call"}</p></div>
                                        }
                                        {this.state.call_counter > 0 &&
                                            <div><p style={{ textAlign: "right" }}>{dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.call.en : "Call"}</p></div>
                                        }

                                    </div>

                                    <div className="contest_msg">
                                        <div className="contest_opt">
                                            <Button size="medium" color="primary" onClick={() => { this.handleNext() }}>
                                            { program && program.partner_id=="14"?"Consent & Submit":dynamic_content.static_text.enterphone ? dynamic_content.static_text.enterphone.submit.en : "Done"}
                                            </Button>
                                        </div>
                                        <p>{dynamic_content.static_text.enterphone ? ReactHtmlParser(dynamic_content.static_text.enterphone.contact_message.en) : "We collect your first name, last name and email address as the most basic account identifiers and default means for you to access your account and us to contact you."}</p>
                                    </div>
                                </div>
                                <div className="program_entry_loader">
                                    <LottieLoader />
                                </div>
                            </div>
                        }

                    </div>
                }
                <div className="open_mail_model">
                    <Modal isOpen={this.state.open_dailog} toggle={this.toggle} className={this.props.className+" custom_Addchildmodel"} backdrop={'static'} keyboard={false}>
                        <ModalBody className="ntcBody_phoneTransferRequest">
                            <div className="ntvcontent_app padding_top">

                                <img src={require('./../../assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.toggle() }} className="ntcimgClose" />
                                
                                <div className="ntc_head">
                                    <h3>{'Phone number is successfully verified and transferred.'}</h3>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        );
    }
}